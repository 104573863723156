//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

// example 1

.btn-group-example {
  .btn {
    position: relative;

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:before {
      content: 'OR';
      position: absolute;
      font-size: 10px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 50%;
      background-color: $gray-600;
      color: $light;
      border: 1px solid $white;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
}

// Example 2

.btn-label {
  position: relative;
  padding-left: 44px;
  .label-icon {
    position: absolute;
    width: 32px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba($white, 0.1);
    border-right: 1px solid rgba($white, 0.1);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.btn-light {
    .label-icon {
      background-color: rgba($dark, 0.1);
      border-right: 1px solid rgba($dark, 0.2);
    }
  }
}

.btn-group-vertical {
  label {
    margin-bottom: 0px;
  }
}

//
// Soft Buttons
//

@mixin button-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.1);
  border-color: transparent;
  transition: all 0.5s ease;
  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $bg;
    border-color: transparent;
  }
}

@each $color, $value in $theme-colors {
  .btn-soft-#{$color} {
    @include button-variant-soft($value);
  }
}

.like-btn {
  &.active {
    .bx-heart {
      color: $danger;
      &:before {
        content: '\ec8f';
      }
    }
  }
}

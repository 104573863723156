//
// _footer.scss
//

.footer {
  bottom: 0;
  padding: 20px calc(#{$grid-gutter-width} * 0.75);
  position: absolute;
  right: 0;
  color: $footer-color;
  left: 250px;
  height: $footer-height;
  background-color: $footer-bg;

  @media (max-width: 991.98px) {
    left: 0;
  }
}

#footer-survey {
  left: 0px !important;
}

// Enlarge menu
.vertical-collpsed {
  .footer {
    left: $sidebar-collapsed-width;

    @media (max-width: 991.98px) {
      left: 0;
    }
  }
}

body[data-layout='horizontal'] {
  .footer {
    left: 0 !important;
  }
}

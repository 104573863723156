.header-bell-container {
  position: relative;
  align-self: center;

  .header-bell {
    font-size: 24px;
    text-align: center;
  }

  .header-bell-badge {
    position: absolute;
    right: -8px;
    top: -8px;
  }
}

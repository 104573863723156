.date-field {
  position: relative;
  .date-icon {
    position: absolute;
    font-size: 16px;
    right: 8px;
    color: #1758B6;
    top: calc(50% - 8px);
    z-index: 99999;
  }
}

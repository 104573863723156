// @import '../variables';

.white-btn {
  box-sizing: border-box;
  // border: 2px solid #1758b6;
  -webkit-box-shadow: inset 0px 0px 0px 2px #1758b6;
  -moz-box-shadow: inset 0px 0px 0px 2px #1758b6;
  box-shadow: inset 0px 0px 0px 2px #1758b6;
  color: #1758b6 !important;
}

.text-noselect {
  user-select: none;
}

.cursor-pointer {
  cursor: pointer;
}

.active-arrow::after {
  transform: rotate(90deg) translate(0, 0);
}

.info-item-title {
  font-weight: 400;
  color: #9a9a9a;
}

.text-grey {
  color: #9a9a9a;
}

.contact-method {
  border-radius: 30px;
  border: 1px solid;
  padding: 1px 10px;

  &.red {
    color: #f46a6a;
    border-color: #f46a6a;
  }

  &.blue {
    color: #50a5f1;
    border-color: #50a5f1;
  }

  &.green {
    color: #34c38f;
    border-color: #34c38f;
  }
}

.seperation-line {
  content: '';
  background-color: #dbdbdb;
  height: 1px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

.select-buttons {
  all: unset;
  cursor: pointer;
  font-size: 12px;
  padding: 9px 0;
  width: 143px;
  text-align: center;
  border: 1px solid #dbdbdb;

  &.active {
    color: #1758b6;
  }

  &.first-button {
    border-radius: 5px 0 0 5px;
  }

  &.last-button {
    border-radius: 0 5px 5px 0;
  }
}

.AM-PM-button {
  all: unset;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  border-radius: 30px;
  border: 1px solid #dbdbdb;
  color: #dbdbdb;
  text-align: center;
  padding: 7px 0;
  width: 44px;

  &.active {
    border: 1px solid #1758b6;
    color: #1758b6;
  }
}

.font-bold {
  font-weight: bold;
}

.font-semibold {
  font-weight: 600;
}

.unset-button {
  all: unset;
}

.report-table {
  color: #363636;
  font-size: 14px;
  overflow: scroll;

  td {
    padding: 15px 20px;
    min-width: 120px;
  }

  .highlighted-cell {
    font-weight: 600 !important;
    color: #1758b6 !important;
  }

  .report-table-head {
    background-color: #f8f9fa;
    font-weight: 600;
    font-size: 13px;
    white-space: nowrap;
  }

  .report-table-body {
    background-color: white;

    tr {
      border-bottom: 1px solid #d9d9d9;
    }
  }

  .report-table-foot {
    background-color: #f8f9fa;

    .foot-description-cell {
      font-weight: 600;
    }
  }
}
